<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="openDialog('add')"
                >新增</el-button
              >
              <el-button
                class="ml-15"
                type="primary"
                @click="deleteAll"
                v-if="deleteId.length"
                >删除</el-button
              >
            </div>
          </div>

          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column label="模板ID" prop="id"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="指定包邮是否开启" prop="appoint">
              <template slot-scope="scope">
                {{
                  scope.row.appoint == 1
                    ? "开启"
                    : scope.row.appoint == 0
                    ? "关闭"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="添加时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    @click="openDialog('edit', scope.row)"
                    class="tip-text-edit"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteData(scope.row.id)"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 添加模板和编辑模板的弹窗 -->
    <el-dialog
      :visible.sync="showDialog"
      :title="opeType == 'add' ? '添加模板' : '编辑模板'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="120px"
          :model="form"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="模板名称" prop="name">
            <el-input
              placeholder="请输入模板名称"
              v-model="form.name"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="计费方式" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">按件数</el-radio>
              <el-radio :label="2">按重量</el-radio>
              <el-radio :label="3">按体积</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="配送区域及运费">
            <el-table :data="form.deliveryArea" border class="dialog-table">
              <el-table-column label="可配送区域">
                <template slot-scope="scope">
                  {{ scope.row.area }}
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  form.type == 2
                    ? '首件重量(KG)'
                    : form.type == 3
                    ? '首件体积(m³)'
                    : '首件'
                "
                prop="firstClothes"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'deliveryArea[' + scope.$index + '].firstClothes'"
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(
                            x,
                            y,
                            z,
                            form.type != 1 ? 'num' : 'string'
                          ),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入首件"
                      v-model="scope.row.firstClothes"
                      class="w-100"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="运费(元)" prop="shipping">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'deliveryArea[' + scope.$index + '].shipping'"
                    :rules="[
                      {
                        validator: myValidate,
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入运费"
                      v-model="scope.row.shipping"
                      class="w-100"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  form.type == 2
                    ? '续件重量(KG)'
                    : form.type == 3
                    ? '续件体积(m³)'
                    : '续件'
                "
                prop="otherClothes"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'deliveryArea[' + scope.$index + '].otherClothes'"
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(
                            x,
                            y,
                            z,
                            form.type != 1 ? 'num' : 'string'
                          ),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入"
                      v-model="scope.row.otherClothes"
                      class="w-100"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="续费(元)" prop="otherPrice">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'deliveryArea[' + scope.$index + '].otherPrice'"
                    :rules="[
                      {
                        validator: myValidate,
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入续费"
                      v-model="scope.row.otherPrice"
                      class="w-100"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <a
                    v-if="scope.row.area !== '默认全国'"
                    @click="delDeliveryArea(scope.row, scope.$index)"
                    >删除</a
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-row class="mt-20">
              <el-button size="small" type="primary" @click="addCity(1)"
                >单独添加配送区域</el-button
              >
            </el-row>
          </el-form-item>
          <el-form-item label="指定包邮" prop="appoint">
            <el-radio v-model="form.appoint" :label="1">包邮</el-radio>
            <el-radio v-model="form.appoint" :label="0">不包邮</el-radio>

            <template v-if="form.appoint == 1">
              <el-table :data="form.freeShipping" border class="mt-10">
                <el-table-column label="选择地区">
                  <template slot-scope="scope">
                    {{ scope.row.area }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="
                    form.type == 2
                      ? '包邮重量(KG)'
                      : form.type == 3
                      ? '包邮体积(m³)'
                      : '包邮件数'
                  "
                  prop="num"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'freeShipping[' + scope.$index + '].num'"
                      :rules="[
                        {
                          validator: (x, y, z) =>
                            myValidate(
                              x,
                              y,
                              z,
                              form.type != 1 ? 'num' : 'string'
                            ),
                          required: true,
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        placeholder="请输入"
                        v-model="scope.row.num"
                        class="w-100"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="包邮金额(元)" prop="price">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'freeShipping[' + scope.$index + '].price'"
                      :rules="[
                        {
                          validator: myValidate,
                          required: true,
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        placeholder="请输入运费"
                        v-model="scope.row.price"
                        class="w-100"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <a @click="delFreeShipping(scope.row, scope.$index)"
                      >删除</a
                    >
                  </template>
                </el-table-column>
              </el-table>

              <el-row class="mt-20">
                <el-button size="small" type="primary" @click="addCity(2)"
                  >单独指定包邮</el-button
                >
              </el-row>
            </template>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showDialog = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showCityDialog"
      title="选择城市"
      @close="clearCityDialog"
    >
      <el-row :gutter="24" type="flex">
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="item">
          <div class="flx-row js-fe">
            <el-checkbox v-model="iSCityselect" @change="allCheckbox"
              >全选</el-checkbox
            >
            <div class="empty" @click="empty">清空</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col
          :xl="6"
          :lg="6"
          :md="6"
          :sm="8"
          :xs="6"
          class="item"
          v-for="(item, index) in cityList"
          :key="index"
        >
          <div @mouseenter="enter(index)" @mouseleave="leave()">
            <el-checkbox
              v-model="item.checked"
              :label="item.name"
              @change="checkedClick(index)"
              >{{ item.name }}</el-checkbox
            >
            <span class="red"
              >({{ (item.count || 0) + "/" + item.children.length }})</span
            >
            <div class="city" v-show="activeCity === index">
              <div class="checkBox">
                <div class="arrow"></div>
                <div>
                  <el-checkbox
                    v-model="city.checked"
                    :label="city.name"
                    @change="primary(index, indexn)"
                    class="itemn"
                    v-for="(city, indexn) in item.children"
                    :key="indexn"
                    >{{ city.name }}</el-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="flx-row ali-c js-fe mt-50">
        <el-button
          plain
          size="small"
          class="form-button-w70"
          @click="showCityDialog = false"
          >取消</el-button
        >
        <el-button
          class="form-button-w70"
          size="small"
          type="primary"
          @click="submitCity"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import {
  getShippingList,
  shippingDelete,
  subShippingForm,
  getAreaList,
} from "@/api/config_center/shipping_config";
export default {
  components: { Pagination },
  data() {
    let myValidate = (rule, value, callback, str) => {
      if (str == "num") {
        if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("正整数"));
        } else if (value === undefined || value === null || value === "") {
          callback(new Error("不能为空"));
        } else {
          callback();
        }
      } else {
        if (!/^[\d]+(\.[\d]{1,2})?$/.test(value)) {
          callback(new Error("不超过两位小数"));
        } else if (value === undefined || value === null || value === "") {
          callback(new Error("不能为空"));
        } else {
          callback();
        }
      }
    };
    return {
      // 会员表格数据内容
      tableData: [],
      showDialog: false,
      opeType: "",
      id: "",
      form: {
        name: "",
        type: 1,
        deliveryArea: [
          {
            area: "默认全国",
            firstClothes: "", //首件
            shipping: "", //运费
            otherClothes: "", //续件
            otherPrice: "", //续费
            cityList: [
              {
                name: "默认全国",
                city_id: 0,
              },
            ],
          },
        ],
        appoint: null,
        freeShipping: [],
      },
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        appoint: [
          { required: true, message: "指定包邮不能为空", trigger: "change" },
        ],
        type: [
          { required: true, message: "计费方式不能为空", trigger: "change" },
        ],
      },
      myValidate: myValidate,
      areaList: [],
      showCityDialog: false,
      loading: false,
      type: null,
      cityList: [],
      activeCity: null,
      iSCityselect: false,
      deleteId: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.search(1);
    this.getAreaListHandle();
  },
  methods: {
    // 获取列表数据
    search(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      getShippingList(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      });
    },
    syncPageData(data) {
      //页数、单页数量改变处理函数
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    getAreaListHandle() {
      //获取地区
      getAreaList().then((res) => {
        this.areaList = res.data.body;
      });
    },
    openDialog(type, item) {
      //打开编辑、新增运费弹窗
      this.showDialog = true;
      this.opeType = type;
      try {
        this.$refs["ruleForm"].resetFields();
      } catch (err) {
        // console.log(err);
      }
      if (item != undefined && item != "undefined") {
        //编辑
        let form = JSON.parse(JSON.stringify(item));
        // console.log("form", form);
        form.region_info = JSON.parse(form.regionInfo);
        form.appoint_info = JSON.parse(form.appointInfo);
        // console.log(form.appoint_info);
        form.deliveryArea = form.region_info.map((item) => {
          return {
            area: item.regionName,
            firstClothes: item.first,
            shipping: item.price,
            otherClothes: item.continue,
            otherPrice: item.continue_price,
            cityList: item.region,
          };
        });
        form.freeShipping = form.appoint_info.map((item) => {
          return {
            area: item.placeName,
            num: item.a_num,
            price: item.a_price,
            cityList: item.place,
          };
        });
        this.form = {
          appoint: form.appoint,
          name: form.name,
          type: form.type,
          deliveryArea: form.deliveryArea,
          freeShipping: form.freeShipping,
          id: item.id,
        };
      } else {
        //新增
        this.form = {
          id: "",
          name: "",
          type: 1,
          deliveryArea: [
            {
              area: "默认全国",
              firstClothes: "", //首件
              shipping: "", //运费
              otherClothes: "", //续件
              otherPrice: "", //续费
              cityList: [
                {
                  name: "默认全国",
                  city_id: 0,
                },
              ],
            },
          ],
          appoint: null,
          freeShipping: [],
        };
      }
    },
    clearDialog() {
      //清除弹窗表格数据
      this.form = {
        name: "",
        type: 1,
        deliveryArea: [
          {
            area: "默认全国",
            firstClothes: "", //首件
            shipping: "", //运费
            otherClothes: "", //续件
            otherPrice: "", //续费
            cityList: [
              {
                name: "默认全国",
                city_id: 0,
              },
            ],
          },
        ],
        appoint: null,
        freeShipping: [],
      };
    },
    delDeliveryArea(row, ind) {
      // 删除指定配送区域信息
      this.form.deliveryArea.splice(ind, 1);
    },
    delFreeShipping(row, ind) {
      // 删除指定包邮区域信息
      this.form.freeShipping.splice(ind, 1);
    },
    handleSelectionChange(val) {
      //多选改变
      this.deleteId = val;
    },
    deleteAll() {
      //批量删除
      this.$confirm("此操作将永久删除所选运费, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let data = JSON.parse(JSON.stringify(this.deleteId));
          data = data.map((item) => item.id);
          shippingDelete(data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteData(id) {
      //删除运费
      this.$confirm("此操作将永久删除该运费, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          shippingDelete([id]).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //模板 点击确认
    submit() {
      //提交运费信息验证
      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      //提交运费信息
      let data = JSON.parse(JSON.stringify(this.form));
      // console.log(data);
      data.region_info = data.deliveryArea.map((item) => {
        return {
          regionName: item.area, //可配送区域
          first: item.firstClothes, //首件
          price: item.shipping, //运费
          _continue: item.otherClothes, //续件
          continue_price: item.otherPrice, //续费
          region: item.cityList,
        };
      });

      data.appoint_info = data.freeShipping.map((item) => {
        return {
          placeName: item.area,
          a_num: item.num,
          a_price: item.price,
          place: item.cityList,
        };
      });
      delete data.deliveryArea;
      delete data.freeShipping;
      // console.log(data);

      subShippingForm(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.showDialog = false;
          this.clearDialog();
          this.search();
        }
      });
    },

    addCity(type) {
      this.type = type;
      this.getCityList();
      this.showCityDialog = true;
    },
    enter(index) {
      //鼠标滑过某个省
      this.activeCity = index;
    },
    leave() {
      //鼠标离开某个省
      this.activeCity = null;
    },
    getCityList() {
      //获取省市的数据
      getAreaList().then((res) => {
        // console.log(res.data.body);
        this.cityList = res.data.body;
      });
    },
    /**
     * 全选或者反选
     * @param checked
     */
    allCheckbox() {
      let that = this,
        checked = this.iSCityselect;
      that.cityList.forEach(function(item, key) {
        that.$set(that.cityList[key], "checked", checked);
        if (checked) {
          that.$set(
            that.cityList[key],
            "count",
            that.cityList[key].children.length
          );
        } else {
          that.$set(that.cityList[key], "count", 0);
        }
        that.cityList[key].children.forEach(function(val, k) {
          that.$set(that.cityList[key].children[k], "checked", checked);
        });
      });
    },
    // 清空选择的省市；
    empty() {
      let that = this;
      that.cityList.forEach(function(item, key) {
        that.$set(that.cityList[key], "checked", false);
        that.cityList[key].children.forEach(function(val, k) {
          that.$set(that.cityList[key].children[k], "checked", false);
        });
        that.$set(that.cityList[key], "count", 0);
      });
      this.iSCityselect = false;
    },
    /**
     * 点击省
     * @param index
     */
    checkedClick(index) {
      let that = this;
      if (that.cityList[index].checked) {
        that.$set(
          that.cityList[index],
          "count",
          that.cityList[index].children.length
        );
        that.cityList[index].children.forEach(function(item, key) {
          that.$set(that.cityList[index].children[key], "checked", true);
        });
      } else {
        that.$set(that.cityList[index], "count", 0);
        that.$set(that.cityList[index], "checked", false);
        that.cityList[index].children.forEach(function(item, key) {
          that.$set(that.cityList[index].children[key], "checked", false);
        });
        that.iSCityselect = false;
      }
    },
    /**
     * 点击市区
     * @param index
     * @param ind
     */
    primary(index) {
      let checked = false,
        count = 0;
      this.cityList[index].children.forEach(function(item) {
        // console.log("item:" + item.checked);
        if (item.checked) {
          checked = true;
          count++;
        }
      });
      this.$set(this.cityList[index], "count", count);
      this.$set(this.cityList[index], "checked", checked);
    },
    // 确定  选择城市;
    submitCity() {
      let that = this;
      // 被选中的省市；
      let selectList = [];
      // console.log("city:", that.cityList[0].children);
      that.cityList.forEach(function(item) {
        let data = {};
        if (item.checked) {
          data = {
            name: item.name,
            city_id: item.id,
            children: [],
          };
        }
        item.children.forEach(function(i) {
          if (i.checked) {
            data.children.push({
              city_id: i.id,
              // name: i.name,
            });
          }
        });
        if (data.city_id !== undefined) {
          selectList.push(data);
        }
      });
      // console.log(selectList);
      if (selectList.length === 0) {
        return this.$message({
          message: "至少选择一个省份或者城市",
          type: "error",
        });
      } else {
        if (this.type == 1) {
          this.$set(this.form.deliveryArea, this.form.deliveryArea.length, {
            cityList: selectList,
            area: selectList.map((item) => item.name).join("、"),
            shipping: "",
            otherPrice: "",
            firstClothes: "",
            otherClothes: "",
          });
        } else {
          this.$set(this.form.freeShipping, this.form.freeShipping.length, {
            cityList: selectList,
            area: selectList.map((item) => item.name).join("、"),
            price: "",
            num: "",
          });
        }
        that.showCityDialog = false;
        this.cityList = [];
      }
    },
    clearCityDialog() {
      this.showCityDialog = false;
      this.cityList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table.dialog-table /deep/ td {
  padding: 20px 0;
}
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
.popover {
  max-height: 400px;
  overflow-y: auto;
}
.item {
  position: relative;
  margin-bottom: 20px;
}

.city {
  position: absolute;
  z-index: 9;
  top: 17px;
  width: 100%;
  padding-top: 18px;
}

.city .checkBox {
  width: 97%;
  padding: 10px;
  border: 1px solid #eee;
  background-color: #fff;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.city .checkBox .arrow {
  position: absolute;
  top: 3px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #ddd;
}

.city .checkBox .arrow:before {
  position: absolute;
  bottom: -8px;
  right: -7px;
  content: "";
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: #fff;
}

.city .checkBox .itemn {
  margin-bottom: 10px;
}

.radio {
  padding: 5px 0;
  font-size: 14px !important;
}

.red {
  color: #ff0000;
}

.empty {
  cursor: pointer;
  margin-left: 10px;
}
</style>
