import request from '@/utils/request'

// 获取运费列表
export function getShippingList (data) {
    return request({
        url: '/customer-service/api/lrShippingTemplates/queryPage',
        method: 'post',
        data
    })
}

// 根据id删除运费
export function shippingDelete (data) {
    return request({
        url: '/customer-service/api/lrShippingTemplates/deleteAll',
        method: 'delete',
        data
    })
}


// 提交新增、编辑运费信息
export function subShippingForm (data) {
    return request({
        url: '/customer-service/api/lrShippingTemplates/save',
        method: 'post',
        data
    })
}

// 获取地区
export function getAreaList (params) {
    return request({
        url: '/customer-service/lrFreightTemplates/queryLrSystemCity',
        method: 'get',
        params
    })
}